/* Navbar styles */
.navbar {
  background-color: #fff;
  padding: 10px 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: fixed;
  width: 100%;
  z-index: 1000;
}

.navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.logo-container {
  flex: 1;
}

.logo-image {
  width: 100px;
  height: auto;
}

.menu-toggle {
  display: none;
  flex-direction: column;
  cursor: pointer;
}

.menu-toggle span {
  width: 30px;
  height: 3px;
  background-color: #333;
  margin-bottom: 5px;
}

.nav-menu {
  display: flex;
  list-style-type: none;
  align-items: center;
}

.nav-menu li {
  margin-right: 20px;
  position: relative;
}

.nav-menu li:last-child {
  margin-right: 0;
}

.nav-menu a {
  text-decoration: none;
  color: #000000;
}

.nav-menu a:hover {
  color: #000000;
}

.login-button {
  background-color: #3b19ff;
  color: #ffffff;
  padding: 12px 36px;
  font-weight: bold;
  border-radius: 5px;
}

.login-button:hover {
  background-color: rgb(0, 0, 0);
  color: #000000;
  border: 2px solid black;
}

/* Dropdown styles */
.dropdown-menu {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.dropdown-menu li {
  padding: 10px 20px;
  border-bottom: 1px solid #eee;
  text-align: center;
  width: 100%;
}

.dropdown-menu li:last-child {
  border-bottom: none;
}

.dropdown:hover .dropdown-menu {
  display: block;
}

/* Mobile responsiveness */
@media screen and (max-width: 768px) {
  .menu-toggle {
    display: flex;
  }

  .nav-menu {
    display: none;
    flex-direction: column;
    background-color: #fff;
    position: absolute;
    top: 60px;
    right: 0;
    width: 100%;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-top: 10%;
  }

  .nav-menu.active {
    display: flex;
  }

  .nav-menu li {
    padding: 10px 20px;
    border-bottom: 1px solid #eee;
    text-align: center;
    width: 100%;
    margin-right: 0;
  }
}




.dropdown-menu {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 10;
  padding: 10px 0;
  width: 300px;
}

.dropdown-menu a{
  text-align: left;
}


@media screen and (max-width: 768px) {
  .menu-toggle {
    display: flex;
  }

  .nav-menu {
    display: none;
    flex-direction: column;
    background-color: #fff;
    position: absolute;
    top: 60px;
    right: 0;
    width: 100%;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-top: 10%;
  }

  .nav-menu.active {
    display: flex;
  }

  .nav-menu li {
    padding: 10px 20px;
    border-bottom: 1px solid #eee;
    text-align: center;
    width: 100%;
    margin-right: 0;
  }

  .dropdown-menu {
    position: static;
    box-shadow: none;
    border-top: 1px solid #eee;
    padding: 0;
  }

  .dropdown-menu li {
    border-bottom: 1px solid #eee;
  }

  .dropdown-menu li:last-child {
    border-bottom: none;
  }

  .dropdown-menu li:hover {
    background-color: transparent;
  }
}

.hidenav {
  display: none;
}